import { useState, useEffect } from 'react';

const LABELS = {
  PHONE: 'PHONE',
  TABLET: 'TABLET',
  DESKTOP: 'DESKTOP',
  MAX: 'MAX',
};

const BREAKPOINTS = {
  PHONE_MAX_WIDTH: 600,
  TABLET_MAX_WIDTH: 960,
  DESKTOP_MAX_WIDTH: 1440,
};

const MEDIA_QUERIES = {
  PHONE: `(max-width: ${BREAKPOINTS.PHONE_MAX_WIDTH}px)`,
  TABLET: `(min-width: ${BREAKPOINTS.PHONE_MAX_WIDTH + 1}px) and (max-width: ${
    BREAKPOINTS.TABLET_MAX_WIDTH
  }px)`,
  DESKTOP: `(min-width: ${BREAKPOINTS.TABLET_MAX_WIDTH +
    1}px) and (max-width: ${BREAKPOINTS.DESKTOP_MAX_WIDTH}px)`,
  MAX: `(min-width: ${BREAKPOINTS.DESKTOP_MAX_WIDTH + 1}px)`,
};

/**
 * Gets the initial document breakpoint variant.
 */
function getInitialVariant() {
  for (const mq in MEDIA_QUERIES) {
    if (matchMediaQuery(mq).matches) {
      return LABELS[mq];
    }
  }
}

/**
 * Gets the document's MediaQueryList
 * @param {MediaQueryList} value
 */
function matchMediaQuery(value) {
  return window.matchMedia(MEDIA_QUERIES[value]);
}

/**
 * A hook that returns enum value based on current matched breakpoint.
 *
 * This is a modern version of useBreakpoint hook that uses the exact
 * breakpoint values found in design tokens: https://designsystem.mailchimp.com/wink/documentation/styles/design-tokens/global-tokens#media_query.
 *
 * @typedef {Object} Breakpoints
 * @property {boolean} isPhone - Whether the phone breakpoint is reached
 * @property {boolean} isTablet - Whether the tablet breakpoint is reached
 * @property {boolean} isDesktop - Whether the desktop breakpoint is reached
 * @property {boolean} isMax - Whether the max breakpoint is reached
 */

export default function useWinkBreakpoints() {
  const [variant, setVariant] = useState(getInitialVariant);

  useEffect(() => {
    let unmounted = false;
    function handlePhone(e) {
      if (e.matches && !unmounted) {
        setVariant(LABELS.PHONE);
      }
    }

    function handleTablet(e) {
      if (e.matches && !unmounted) {
        setVariant(LABELS.TABLET);
      }
    }

    function handleDesktop(e) {
      if (e.matches && !unmounted) {
        setVariant(LABELS.DESKTOP);
      }
    }
    function handleMax(e) {
      if (e.matches && !unmounted) {
        setVariant(LABELS.MAX);
      }
    }

    function registerModernListeners() {
      matchMediaQuery(LABELS.PHONE).addEventListener('change', handlePhone);
      matchMediaQuery(LABELS.TABLET).addEventListener('change', handleTablet);
      matchMediaQuery(LABELS.DESKTOP).addEventListener('change', handleDesktop);
      matchMediaQuery(LABELS.MAX).addEventListener('change', handleMax);
    }

    function registerLegacyListeners() {
      matchMediaQuery(LABELS.PHONE).addListener(handlePhone);
      matchMediaQuery(LABELS.TABLET).addListener(handleTablet);
      matchMediaQuery(LABELS.DESKTOP).addListener(handleDesktop);
      matchMediaQuery(LABELS.MAX).addListener(handleMax);
    }

    function unregisterModernListeners() {
      matchMediaQuery(LABELS.PHONE).removeEventListener('change', handlePhone);
      matchMediaQuery(LABELS.TABLET).removeEventListener(
        'change',
        handleTablet,
      );
      matchMediaQuery(LABELS.DESKTOP).removeEventListener(
        'change',
        handleDesktop,
      );
      matchMediaQuery(LABELS.MAX).removeEventListener('change', handleMax);
    }

    function unregisterLegacyListeners() {
      matchMediaQuery(LABELS.PHONE).removeListener(handlePhone);
      matchMediaQuery(LABELS.TABLET).removeListener(handleTablet);
      matchMediaQuery(LABELS.DESKTOP).removeListener(handleDesktop);
      matchMediaQuery(LABELS.MAX).removeListener(handleMax);
    }

    // Adds an event listener to each of the breakpoint variants

    // Older browsers (Safari 14 and older, for example) don't
    // support addEventListener on the MediaQueryList type,
    // supporting addListener instead.
    try {
      registerModernListeners();
    } catch (e) {
      if (e instanceof TypeError) {
        registerLegacyListeners();
      } else {
        throw e;
      }
    }

    return () => {
      unmounted = true;
      // Cleans up event listeners when unmounted
      try {
        unregisterModernListeners();
      } catch (e) {
        if (e instanceof TypeError) {
          unregisterLegacyListeners();
        } else {
          throw e;
        }
      }
    };
  }, []);

  return {
    isPhone: variant === LABELS.PHONE,
    isTablet: variant === LABELS.TABLET,
    isDesktop: variant === LABELS.DESKTOP,
    isMax: variant === LABELS.MAX,
    deviceType: variant,
  };
}
