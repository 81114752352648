/**
 * Append ellipsis to a given string
 *
 * https://en.wikipedia.org/wiki/Ellipsis
 *
 * @param {String} str Value you want to append ellipsis to
 * @returns String
 */
export function append(str) {
  // Unicode representation of ellipsis for accessibility and easier integration
  // into JSX without the need to dangerouslySetInnerHTML.
  return `${str}…`;
}

/**
 * Truncate a string
 *
 * @param {String} str Value you potentially want to add ellipsis to.
 * @param {Number} maxChars Max length you want a string before it truncates. Defaults to 20.
 * @returns String
 */
export function truncate(str, maxChars = 20) {
  return str.length > maxChars ? `${append(str.substring(0, maxChars))}` : str;
}

export default { append, truncate };
